import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import themes from 'src/styles/themes'

import { CardBigNews, Card, Label } from './style'

type categoryPros = {
  icon: string
  id: string
  name: string
  slug: string
  subTitle: string
  template: string
}

type dataProps = {
  showCategoryLabel: string
  category: categoryPros
  image: string
  alt: string
  slug: string
  title: string
  subTitle: string
  categorySlug: string
  subcategorySlug: string
  segmentSlug: string
  subCategory: string
  isFromInset?: boolean
  insetCategory?: string
}

type HeroNewsProps = {
  data: dataProps[]
}

const HeroNews = ({ data }: HeroNewsProps) => {
  const [sendDatalayerEvent] = useDataLayer()
  const searchColor = (category: string) => {
    const categoryColor = themes.find((item) => item.title === category)
    return categoryColor
  }
  return (
    <div className="row">
      {data[0] && (
        <div className="col-12 col-md-8 col-lg-6">
          <CardBigNews>
            <a
              href={
                !data[0].isFromInset
                  ? `/${data[0].categorySlug}/${data[0].subcategorySlug}/${data[0].slug}`
                  : `https://www.inset.com.br/${data[0].insetCategory}/${data[0].slug}`
              }
              onClick={() => {
                sendDatalayerEvent({
                  section: data[0].title,
                  element_action: 'click banner',
                  element_name: data[0].title,
                  redirect_url: `${process.env.BASE_URL}/${data[0].categorySlug}/${data[0].subcategorySlug}/${data[0].slug}`,
                })
              }}
              target={data[0].isFromInset ? '__blank' : ''}
              rel={data[0].isFromInset ? 'noreferrer' : ''}
              title={data[0].title}
            >
              <figure>
                {data[0].image && <img loading="lazy" src={data[0].image} alt={data[0].alt} />}
                <figcaption className="d-flex align-items-end">
                  <Label bg={searchColor(data[0].categorySlug)?.colors.primary}>
                    {data[0].subCategory}
                  </Label>
                  <div>
                    <h3 className="fs-24 lh-30 fs-xl-40 lh-xl-50 text-white">{data[0].title}</h3>
                    <p className="fs-14 lh-17 fs-xl-16 lh-xl-19 text-white">{data[0].subTitle}</p>
                  </div>
                </figcaption>
              </figure>
            </a>
          </CardBigNews>
        </div>
      )}
      {data[1] && (
        <div className="col-12 col-md-4 col-lg-6">
          <div className="row">
            <div className="col-12 col-lg-6 mb-md-3 mb-lg-0">
              <Card>
                <a
                  href={
                    !data[1].isFromInset
                      ? `/${data[1].categorySlug}/${data[1].subcategorySlug}/${data[1].slug}`
                      : `https://www.inset.com.br/${data[1].insetCategory}/${data[1].slug}`
                  }
                  onClick={() => {
                    sendDatalayerEvent({
                      section: data[1].title,
                      element_action: 'click banner',
                      element_name: data[1].title,
                      redirect_url: `${process.env.BASE_URL}/${data[1].categorySlug}/${data[1].subcategorySlug}/${data[1].slug}`,
                    })
                  }}
                  target={data[1].isFromInset ? '__blank' : ''}
                  rel={data[1].isFromInset ? 'noreferrer' : ''}
                  title={data[1].title}
                >
                  <figure>
                    {data[1].image && <img loading="lazy" src={data[1].image} alt="" />}
                    <figcaption className="d-flex align-items-end">
                      <Label bg={searchColor(data[1].categorySlug)?.colors.primary}>
                        {data[1].subCategory}
                      </Label>
                      <h3 className="fs-20 lh-25 text-white">{data[1].title}</h3>
                    </figcaption>
                  </figure>
                </a>
              </Card>
            </div>
            {data[2] && (
              <div className="col-12 col-lg-6">
                <Card>
                  <a
                    href={
                      !data[2].isFromInset
                        ? `/${data[2].categorySlug}/${data[2].subcategorySlug}/${data[2].slug}`
                        : `https://www.inset.com.br/${data[2].insetCategory}/${data[2].slug}`
                    }
                    onClick={() => {
                      sendDatalayerEvent({
                        section: data[2].title,
                        element_action: 'click banner',
                        element_name: data[2].title,
                        redirect_url: `${process.env.BASE_URL}/${data[2].categorySlug}/${data[2].subcategorySlug}/${data[2].slug}`,
                      })
                    }}
                    target={data[2].isFromInset ? '__blank' : ''}
                    rel={data[2].isFromInset ? 'noreferrer' : ''}
                    title={data[2].title}
                  >
                    <figure>
                      {data[2].image && <img loading="lazy" src={data[2].image} alt="" />}
                      <figcaption className="d-flex align-items-end">
                        <Label bg={searchColor(data[2].categorySlug)?.colors.primary}>
                          {data[2].subCategory}
                        </Label>
                        <h3 className="fs-20 lh-25 text-white">{data[2].title}</h3>
                      </figcaption>
                    </figure>
                  </a>
                </Card>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default HeroNews

